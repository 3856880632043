// import WebSocket from 'ws';
import { useEffect, useState } from 'react';
import { WS_MAIN_URL } from '../utils/constants';

export default function useMainWebsocket({ id }) {
    const [websocket, setWebsocket] = useState(null);
    useEffect(() => {
        if (!id) {
            return;
        }
        const openWebSocket = () => {
            const ws = new WebSocket(`${WS_MAIN_URL}`)
            setWebsocket(ws);
            ws.onopen = () => {
                console.log('Websocket connection opened');
            };
            ws.onmessage = (event) => {
                console.log('Received message:', event.data);
            };
            ws.onclose = () => {
                setWebsocket(null);
                console.log('Websocket connection closed');
                // Try to re-open the WebSocket connection after 5 seconds
                setTimeout(openWebSocket, 5000);
            };
        };
        openWebSocket();
        return () => {
            if (websocket) {
                websocket.close();
            }
        };
    }, [id]);
    return websocket;
}
