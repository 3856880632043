import { useEffect, useState } from 'react';
import NotificationColumn from '../NotificationColumn/NotificationColumn';
import TableSchema from '../TableSchema/TableSchema';
import './App.css';
import { data } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import useMainWebsocket from '../../assets/hooks/useMainWebSocket';
import DeleteConfirmPopup from '../DeleteConfirmPopup/DeleteConfirmPopup';

function App() {
  let final = ''
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker
  //     .register('/sw.js')
  //     .then(event => {
  //       // console.log('Service worker registered', event);
  //     });
  // }

  const [mainWsMessages, setMainWsMessages] = useState([])
  const [task, setTasks] = useState([])
  const [isTasks, setIsTasks] = useState(false)

  const mainWS = useMainWebsocket({ id: isTasks ? isTasks : null })

  const [isOpen, setOpen] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [curId, setCurId] = useState(null)
  const [preloaderSubmit, setPreloaderSubmit] = useState(false)
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false)

  useEffect(() => {
    if (!mainWS) {
      return;
    }
    mainWS.onmessage = (e) => {
      console.log('Received message app:', e.data);
      if (e.data !== 'Missing CSRF Token' && e.data !== 'Missing cookie access_token_cookie' && e.data !== 'Missing cookie refresh_token_cookie' && e.data !== 'Could not find the current user') {
        const res = JSON.parse(e.data)
        if (res.active) {
          setTasks(prevVal => [...prevVal.filter(n => !(n.type === res.type && Number(n.table) === Number(res.table))), res])
        }
        else {
          setTasks(preVal => preVal.filter(item => item.id !== res.id))
        }
      }
    }
  }, [mainWS]);

  useEffect(() => {
    // console.log(mainWsMessages)
  }, [mainWsMessages])

  useEffect(() => {
    setIsPreloaderVisible(true)
    mainApi
      .getAllNotification()
      .then((res) => {
        setIsTasks(true)
        setTasks(res.data.reverse());
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }, [])


  function Submit() {
    setSubmit(true)
    setOpen(false)
    setPreloaderSubmit(true);
    mainApi
      .deleteNotification({ deletId: curId })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPreloaderSubmit(false);
      });
  }
  console.log(task)
  return (
    <div className="app">
      <TableSchema data={task} />
      {
                        isOpen ?
                            <DeleteConfirmPopup
                                {...{
                                    setOpen,
                                    curId,
                                    Submit,
                                    preloaderSubmit,
                                }}
                            />
                            : null
                    }
      <NotificationColumn
        data={task}
        Submit={Submit}
        setOpen={setOpen}
        setCurId={setCurId}
        curId={curId}
        isOpen={isOpen}
        preloaderSubmit={preloaderSubmit}
        isPreloaderVisible={isPreloaderVisible}
      />
    </div>
  );
}

export default App;
