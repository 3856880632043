import {  motion } from "framer-motion";

import MiniPreloader from '../MiniPreloader/MiniPreloader';
import './DeleteConfirmPopup.css';

function DeleteConfirmPopup({
    Submit,
    curId,
    setOpen,
    preloaderSubmit,

}) {
    return (
        <div className='delete-confirm-popup'>
            <motion.div
                key={`${curId}popup-bg`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.2 }}
                transition={{ duration: 0.5 }}
                onClick={() => setOpen(false)} className='delete-confirm-popup__bg'>
            </motion.div>
            <motion.div
                key={`${curId}popup`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className='delete-confirm-popup__container'>
                <p className='delete-confirm-popup__text'>Удалить задачу из списка?</p>
                <button onClick={() => Submit()} className='delete-confirm-popup__button'>
                    {preloaderSubmit ? <MiniPreloader /> : 'ok'}
                </button>
            </motion.div>
        </div>
    );
}

export default DeleteConfirmPopup