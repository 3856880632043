import './TableSchema.css';
import waiter from '../../assets/images/waiter.svg'
import hook from '../../assets/images/hook.svg'
import check from '../../assets/images/bill.svg'
import { motion } from 'framer-motion'
function TableSchema({ data }) {
    const TABLES = [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
    ]

    return (
        <div className="table-schema">
            {TABLES.map((item, i) => {
                // console.log((data.find(city => city.table === i + 1)) ? (data.find(city => city.table === i + 1)).type : '')
                // console.log(data.filter(function (n) {
                //     return n.table == i + 1;
                // }).length > 0
                //     ?
                //     data.filter(function (n) {
                //         return n.table == i + 1;
                //     })
                //     : '')
                let barman = data?.filter(function (n) {
                    return n.type == 'barman';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'barman';
                    })
                    : ''

                let barman2 = (barman ? barman.map((item) => {
                    return (
                        item.table
                    )
                }) : '')

                let hookah = data?.filter(function (n) {
                    return n.type == 'hookah';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'hookah';
                    })
                    : ''

                let hook2 = (hookah ? hookah.map((item) => {
                    return (
                        item.table
                    )
                }) : '')

                let checkt = data?.filter(function (n) {
                    return n.type == 'check';
                }).length > 0
                    ?
                    data?.filter(function (n) {
                        return n.type == 'check';
                    })
                    : ''

                let check2 = (checkt ? checkt.map((item) => {
                    return (
                        item.table
                    )
                }) : '')

                return (
                    <div key={i} className='table__box' style={{ gridArea: `table${i + 1}`, color: data?.find(city => city.table === i + 1) ? '#FFB31F' : '', backgroundColor: data?.find(city => city.table === i + 1) ? '#3D3D54' : '' }}>
                        <div className='table__box__icons'>
                            {barman2.includes(i + 1) ?
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt='' className='table__box__icons__img' src={waiter}>
                                </motion.img>
                                : null}
                            {hook2.includes(i + 1) ?
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt='' className='table__box__icons__img' src={hook}>
                                </motion.img>
                                : null}
                            {check2.includes(i + 1) ?
                                <motion.img
                                    layout
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    alt='' className='table__box__icons__img' src={check}>
                                </motion.img>
                                : null}
                        </div>
                        {i + 1 === 11 ?
                            <p className='table__box__numbers' style={{ color: data?.find(city => city.table === i + 1) ? '#FFB31F' : '' }}>{i + 1} VIP</p>
                            :
                            <p className='table__box__numbers' style={{ color: data?.find(city => city.table === i + 1) ? '#FFB31F' : '' }}>{i + 1}</p>}
                    </div>
                )
            })}
        </div>
    );
}

export default TableSchema;