import React, { useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable';
import './SwipeableCard.css'
import moment from "moment-timezone";

function SwipeableCard({ item, Cur }) {
    const [isDrag, setIsDrag] = useState(false)
    const [isActionOpen, setIsActionOpen] = useState(false)
    const [percent, setPercent] = useState(0)
    const [left, setLeft] = useState(0)

    const itemRef = useRef()
    const actionRef = useRef()

    const handleStart = (e, data) => {
        if (left > 0) {
            setIsDrag(false)
            setLeft(0)

        } else {
            setIsDrag(true)
        }
    }

    const handleClick = () => {
        console.log(isDrag, left)
        if (left !== 0) {
            setIsActionOpen(false)
            setLeft(0)
        } else {
            console.log('click')
        }
    }

    const handleStop = (e) => {
        if (percent > 10) {
            setIsActionOpen(true)
            const w = actionRef?.current.offsetWidth
            const leftWithAction = left > 0 ? 0 : w * -1
            setLeft(leftWithAction)
            Cur(item.id);
        } else {
            setIsActionOpen(false)
            setLeft(0)
        }
        setIsDrag(false)
    }

    const handleDrag = (e, data) => {
        if (data.x > 0) {
            return null
        } else {
            const w = itemRef?.current.offsetWidth
            const x = data.x < 0 ? data.x * -1 : 0
            const p = x / w * 100

            setPercent(p)
            setLeft(data.x)
        }
    }

    const [now, setNow] = useState(moment())
    useEffect(() => {
        setTimeout(() => {
            setNow(moment())
        }, 1000);
    }, [now])
    const local_date = moment.utc(item.utc_datetime).local().format('YYYY-MM-DD HH:mm:ss');

    const opacGreen = moment(now).diff(local_date, 'seconds') > 79 ? (100 - (moment(now).diff(local_date, 'seconds') - 79)) / 100 : 1
    const opacOrange = moment(now).diff(local_date, 'seconds') > 139 ? ((100 - (moment(now).diff(local_date, 'seconds') - 139)) * 1.69) / 100 : 1

    return (
        <div className='swipeable-card'>
            <Draggable
                axis="x"
                handle=".swipeable-card__item"
                defaultPosition={{ x: 0, y: 0 }}
                position={{ x: left, y: 0 }}
                bounds={{ top: 0, right: 0, bottom: 0, left: -85 }}
                onStart={handleStart}
                onDrag={handleDrag}
                onStop={handleStop}
            >
                <div
                    ref={itemRef}
                    className='swipeable-card__item'
                    style={{ transform: `translate(${left}px, 0px)` }}
                // onClick={handleClick}
                >
                    <div className='swipeable-card__item-inner'>
                        <div className='swipeable-card__task__call-and-time'>
                            <p className='swipeable-card__task__call'>{item.table < 10 ? `Стол 0${item.table}` : `Стол ${item.table}`} </p>
                            <p style={{ color: `rgba(88, 255, 61, ${opacGreen})`, zIndex: 3 }} className='swipeable-card__task__time'>
                                {moment(now).diff(local_date, 'hours') > 0 ? `${moment(now).diff(local_date, 'hours')}:` : null}
                                {moment(moment(now).diff(local_date, 'mm:ss')).format('mm:ss')}
                            </p>
                            <p style={{ color: `rgba(255, 166, 61, ${opacOrange})`, zIndex: 2 }} className='swipeable-card__task__time'>
                                {moment(now).diff(local_date, 'hours') > 0 ? `${moment(now).diff(local_date, 'hours')}:` : null}
                                {moment(moment(now).diff(local_date, 'mm:ss')).format('mm:ss')}
                            </p>
                            <p style={{ color: `red`, zIndex: 1, left: '97px' }} className='swipeable-card__task__time'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="20" viewBox="0 0 6 20" fill="none">
                                    <circle cx="3" cy="10" r="3" fill="#8A8AA3" />
                                </svg>
                                <svg className='swipeable-card__task__time__timer-icon' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.38599 1.76562H11.7544" stroke="#8A8AA3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 18.2344C13.866 18.2344 17 15.1004 17 11.2344C17 7.36838 13.866 4.23438 10 4.23438C6.13401 4.23438 3 7.36838 3 11.2344C3 15.1004 6.13401 18.2344 10 18.2344Z" stroke="#8A8AA3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.0702 11.4979L13.1018 8.46631" stroke="#8A8AA3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {moment(now).diff(local_date, 'hours') > 0 ? `${moment(now).diff(local_date, 'hours')}:` : null}
                                {moment(moment(now).diff(local_date, 'mm:ss')).format('mm:ss')}
                            </p>

                        </div>
                        <p className='swipeable-card__task__call-type'>{item.type === 'check' ? 'Счет' : item.type === 'hookah' ? 'Кальянный мастер' : item.type === 'barman' ? 'Бармен' : null}</p>
                        <div className='swipeable-card__task__dots' onClick={() => setLeft(-85)}>
                            <svg className='swipeable-card__task__dots-svg' xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26" fill="none">
                                <circle cx="3" cy="4" r="2" fill="#FFB31F" />
                                <circle cx="3" cy="13" r="2" fill="#FFB31F" />
                                <circle cx="3" cy="22" r="2" fill="#FFB31F" />
                            </svg>
                        </div>
                    </div>
                    <button
                        className='swipeable-card__box-action'
                        ref={actionRef}
                        type='button'
                        
                        onClick={(e) => {
                            e.preventDefault()
                            Cur(item.id)
                        }}
                    >
                        <div className='swipeable-card__action'>
                            Закрыть
                        </div>
                    </button>
                </div>
            </Draggable>
        </div>
    )
}

export default SwipeableCard