import { MAIN_URL } from "../utils/constants";
import { Buffer } from 'buffer';
import { encode } from "base-64";
import { tab } from "@testing-library/user-event/dist/tab";

const username = process.env.REACT_APP_AUTH_USERNAME;
const password = process.env.REACT_APP_AUTH_PASSWORD;

class MainApi {
    constructor({ baseUrl }) {
        this._BASE_URL = baseUrl;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.msg,
                    detail: res.detail,
                });
            });
        }
    }

    _checkResponseWithCookies({ res, func, params }) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                if (statusCode === 422 && res.detail === "Signature has expired") {
                    console.log("ss");
                    return mainApi
                        .refreshJWT()
                        .then((res) => {
                            return func(params);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                } else {
                    return Promise.reject({
                        statusCode: statusCode,
                        message: res.msg,
                        detail: res.detail,
                    });
                }
            });
        }
    }

    refreshJWT() {
        return fetch(`${MAIN_URL}/users/refresh-jwt`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then(this._checkResponse);
    }

    getAllNotification() {
        return fetch(`${MAIN_URL}/notification/get-all`, {
            method: "GET",
            credentials: 'same-origin',
            headers: {
                'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64'),
                'Content-Type': 'application/json'
            },
        }).then(this._checkResponse);
    }

    deleteNotification({ deletId }) {
        return fetch(`${MAIN_URL}/notification/delete`, {
            method: "POST",
            headers: {
                'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                orders: [
                   deletId
                  ]
            })
        }).then((res) => mainApi._checkResponseWithCookies({
            res: res,
            func: mainApi.deleteNotification,
            params: { deletId }
        }))
    }
}

const mainApi = new MainApi({
    baseUrl: MAIN_URL,
});

export default mainApi;