import './NotificationColumn.css';
import { AnimatePresence, motion } from "framer-motion";
import cosmo from '../../assets/images/cosmo.png';
import SwipeableCard from '../SwipeableCard/SwipeableCard';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { useEffect, useState } from 'react';
import mainApi from '../../assets/api/MainApi';
import useMainWebsocket from '../../assets/hooks/useMainWebSocket';

function NotificationColumn({ setOpen, setCurId, data, isPreloaderVisible, Submit, preloaderSubmit, isOpen }) {

    function Cur(itemId) {
        setOpen(true)
        setCurId(itemId)
    }

    return (
        <>
            <div id='notification-column' className="notification-column">
                <div className={`notification-column__box`} >
                    {/* <img src={cosmo} alt='' className='notification-column__header__img'></img> */}
                    <div className='notification-column__header'>
                        <img src={cosmo} alt='' className='notification-column__header__img'></img>
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="22" viewBox="0 0 45 22" fill="none">
                            <path d="M1.23409 12.3051C0.655302 11.9049 0.655302 11.0951 1.23409 10.6949C3.95436 8.81374 7.22116 7.8 10.575 7.8L19.9997 7.8C20.6904 7.8 21.2 8.33255 21.2 8.92856L21.2 14.0714C21.2 14.6674 20.6904 15.2 19.9997 15.2L10.575 15.2C7.22115 15.2 3.95436 14.1863 1.23409 12.3051Z" stroke="#777786" strokeWidth="1.6" />
                            <path d="M11.0601 15.4531L12.6804 18.76C13.3521 20.131 14.7457 21 16.2723 21L21 21" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                            <path d="M11.0601 7L12.6804 3.69313C13.3521 2.32216 14.7457 1.45312 16.2723 1.45312L21 1.45312" stroke="#777786" strokeWidth="1.6" strokeLinecap="round" />
                            <path d="M18.3335 21L18.3335 16" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                            <path d="M18.3335 1.45312L18.3335 6.45313" stroke="#777786" strokeWidth="1.6" strokeLinecap="square" />
                            <path d="M0.86936 3.64165C0.747323 3.58653 0.747323 3.41323 0.86936 3.35811L2.37719 2.6771C2.41171 2.66152 2.43935 2.63387 2.45493 2.59936L3.13594 1.09153C3.19106 0.969492 3.36436 0.969492 3.41948 1.09153L4.10049 2.59936C4.11607 2.63387 4.14371 2.66152 4.17822 2.6771L5.68606 3.35811C5.8081 3.41323 5.8081 3.58653 5.68606 3.64165L4.17822 4.32265C4.14371 4.33824 4.11607 4.36588 4.10049 4.40039L3.41948 5.90823C3.36436 6.03027 3.19106 6.03026 3.13594 5.90823L2.45493 4.40039C2.43935 4.36588 2.41171 4.33824 2.37719 4.32265L0.86936 3.64165Z" fill="#777786" />
                            <path d="M28 11H44" stroke="#FFB31F" strokeWidth="1.6" strokeLinecap="round" />
                            <path d="M28 17H40" stroke="#FFB31F" strokeWidth="1.6" strokeLinecap="round" />
                            <path d="M28 5H40" stroke="#FFB31F" strokeWidth="1.6" strokeLinecap="round" />
                        </svg>
                        <p className='notification-column__title'>СОЮЗ 99</p>
                    </div>
                    {data?.length > 0 ?
                        <>
                            <AnimatePresence >
                                {
                                    isPreloaderVisible ?
                                        <MiniPreloader />
                                        :
                                        data?.map((item, i) => (
                                            <motion.div
                                                layout
                                                initial={{ y: 300 }}
                                                animate={{ y: 0 }}
                                                key={item.id}
                                                exit={{ x: 500 }}
                                                transition={{ duration: 0.1 }}
                                                className={`notification-column__swipeable-card-content`}
                                            >
                                                <SwipeableCard
                                                    item={item}
                                                    Cur={Cur}
                                                />
                                            </motion.div>
                                        ))
                                }
                            </AnimatePresence>
                        </>
                        :
                        <p className='notification-column_empty'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="90" viewBox="0 0 43 90" fill="none">
                                <path d="M19.3076 2.41134C20.1479 1.19622 21.8524 1.19622 22.6927 2.41134C26.4668 7.86889 28.5003 14.4225 28.5003 21.1504L28.5003 40.0002C28.5003 41.4333 27.3938 42.5008 26.1431 42.5008L15.8572 42.5008C14.6065 42.5008 13.5 41.4333 13.5 40.0002L13.5 21.1504C13.5 14.4225 15.5336 7.86889 19.3076 2.41134Z" stroke="#777786" strokeWidth="3" />
                                <path d="M12.6667 22L4.17514 26.3534C2.83984 27.038 2 28.4123 2 29.9129L2 42.0001" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M30.0004 22.1206L38.8544 26.4588C40.2253 27.1306 41.0944 28.5241 41.0944 30.0508L41.0944 42.0008" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M2 36.668L12.0002 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M41.0944 36.668L31.0942 36.668" stroke="#777786" strokeWidth="3" strokeLinecap="square" />
                                <path d="M36.7165 1.73872C36.8268 1.49464 37.1734 1.49464 37.2836 1.73872L38.6457 4.75445C38.6768 4.82347 38.7321 4.87875 38.8012 4.90993L41.8169 6.27197C42.061 6.3822 42.061 6.72881 41.8169 6.83905L38.8012 8.20109C38.7321 8.23226 38.6768 8.28755 38.6457 8.35657L37.2836 11.3723C37.1734 11.6164 36.8268 11.6164 36.7165 11.3723L35.3545 8.35657C35.3233 8.28755 35.2681 8.23226 35.199 8.20109L32.1833 6.83905C31.9392 6.72881 31.9392 6.3822 32.1833 6.27197L35.199 4.90993C35.2681 4.87875 35.3233 4.82347 35.3545 4.75445L36.7165 1.73872Z" fill="#FFB31F" />
                                <path d="M22.0011 56.001L22.0011 88.0016" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M10.0012 56.001L10.0012 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                                <path d="M34.001 56.001L34.001 80.0014" stroke="#777786" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                            Активных заказов нет
                        </p>
                    }
                </div>
            </div>
        </>
    );
}

export default NotificationColumn;